import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import '../../components/layout.css'
import { 
  Box
} from "@chakra-ui/react"
import Seo from "../../Seo"
import SliceZone from '../../components/SliceZone/SliceZone'

import PageHero from "../../components/PageHero/PageHero"

const AboutUsTemplate = ({ data }) => {

  const document = data.prismicAboutUsPage.data;
  console.log(document)

  return (
    <Layout>
      <Seo title="About Us | Plateau Forest Products" />
      <PageHero 
        heroImage={document.hero_image?.localFile}
        alt={document.hero_image.alt}
        pageTitle={document.page_title.text}
        pageSubtitle={document.page_subtitle.text}
      />
        <Box>
          <SliceZone sliceZone={document.body}/>
        </Box>
    </Layout>
  )
}

export const query = graphql`
query 
{
  prismicAboutUsPage(uid: {eq: "environmental-sustainability"}) {
    uid
    data {
      page_title {
        text
      }
      page_subtitle {
        text
      }
      hero_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 80, placeholder: BLURRED)
          }
        }
        alt
      }
      body {
        ... on PrismicAboutUsPageDataBodyWhiteCallToActionBlock {
          id
          slice_type
          primary {
            yellow_title {
              text
            }
            white_headline {
              text
            }
            button_link {
              uid
              url
            }
          }
        }
        ... on PrismicAboutUsPageDataBodyWhatWeTradeBlock {
          id
          slice_type
          items {
            short_description {
              text
            }
            product_name {
              text
            }
            product_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        ... on PrismicAboutUsPageDataBodyTestimonials {
          id
          slice_type
          primary {
            title {
              text
            }
          }
          items {
            testimonial_text {
              text
            }
            person_name {
              text
            }
            company_name {
              text
            }
          }
        }
        ... on PrismicAboutUsPageDataBodyQuoteBlock {
          id
          slice_type
          primary {
            quote {
              text
            }
          }
        }
        ... on PrismicAboutUsPageDataBodyMessageBlock {
          id
          slice_type
          primary {
            title {
              text
            }
            text_content {
              raw
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        ... on PrismicAboutUsPageDataBodyIndustryMembers {
          slice_type
          primary {
            industry_partners {
              document {
                ... on PrismicIndustryPartners {
                  data {
                    industry_member {
                      industry_logo {
                        alt
                        copyright
                        url
                        localFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                          }
                        }
                      }
                      industry_link {
                        url
                        target
                      }
                    }
                  }
                }
              }
            }
          }
          id
        }
        ... on PrismicAboutUsPageDataBodyGreenCallToActionBlock {
          id
          slice_type
          primary {
            yellow_title {
              text
            }
            white_headline {
              text
            }
            button_link {
              url
              uid
            }
            button_label {
              text
            }
          }
        }
        ... on PrismicAboutUsPageDataBodyContentArea {
          id
          slice_type
          primary {
            white_or_green_block_
            content_title {
              text
            }
            content {
              raw
            }
            center_content
          }
        }
        ... on PrismicAboutUsPageDataBodyCompanyOverviewBlock {
          id
          slice_type
          primary {
            description {
              text
            }
            block_title {
              text
            }
          }
        }
        ... on PrismicAboutUsPageDataBodyBusinessPromise {
          id
          slice_type
          primary {
            statement {
              text
            }
            link_label {
              text
            }
            link {
              url
              uid
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    transformOptions: {cropFocus: CENTER}
                  )
                }
              }
            }
            explanation {
              text
            }
          }
        }
        ... on PrismicAboutUsPageDataBodyLeftColumn {
          id
          slice_type
          primary {
            right_column_content {
              raw
            }
            left_column_content {
              raw
            }
          }
        }
        ... on PrismicAboutUsPageDataBodyTeamMembers {
          id
          slice_type
          primary {
            team_title {
              text
            }
          }
          items {
            email {
              text
            }
            name {
              text
            }
            phone2 {
              text
            }
            phone1 {
              text
            }
            title {
              text
            }
            team_member_photo {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    transformOptions: {cropFocus: CENTER}
                  )
                }
              }
            }
          }
        }
        ... on PrismicAboutUsPageDataBodyTeamImageSlider {
          id
          items {
            action_shot {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
          slice_type
        }
      }
    }
  }
}
`


export default AboutUsTemplate